import React from 'react'
import starIcon from '../../assets/images/star-icon.png'

const Pricing = () => {
    return (
        <div className="membership-levels-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="Pricing" /> 
                        Pricing
                    </span>
                    <h2>How much does Kubelink cost?</h2>
                      <p>There are no upfront costs and you pay only for the resources you use. You pay for the amount of CPU, memory, and storage resources consumed by your containerized applications running on Kubelink.</p>
                      <p>Compute usage is billed on a per-second basis, with a minimum of 60 seconds.</p>
                </div>

                <div className="membership-levels-table table-responsive">
                <table className="table table-striped">
                            <thead>
                               <tr>
                                    <th>
 
                                        <span className="title">Pay-as-you-go Kubelink Plan</span>
                                        <span className="desc">Kubernetes Resources</span>
         
                                    </th>
                                    <th> <span className="title">Price</span></th>
                               </tr>
                            </thead>
                                    <tbody>
                                        <tr>
                                        <td>per CPU per hour</td><td>$0.04</td>
                                        </tr>
                                        <tr>
                                        <td>per GB Storage per hour</td><td>$0.004</td>
                                        </tr>
                                    </tbody>
                        </table>
                        <br></br>
                        
                        <table className="table table-striped">
                            <thead>
                               <tr>
                                    <th>
 
                                        <span className="title">Always Free</span>
                                        <span className="desc">Kubernetes Concepts</span>
         
                                    </th>
                                    <th> <span className="title">Free</span></th>
                               </tr>
                            </thead>
                                    <tbody>
                                        <tr><td>Deployments</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>ReplicaSets</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>StatefulSets</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>CronJobs</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>Ingress</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>ConfigMaps</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>Secrets</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>Pods</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        <tr><td>Cusom Domains</td><td className="item-check"><i className='bx bx-check'></i></td></tr>
                                        
                                    </tbody>
                        </table>
                </div>
                      <blockquote className="blockquote">
                         <p>Get the required number of CPU and RAM resources for your containers without the need to reserve them in advance. In this way, you won’t over-allocate beyond what is needed, especially during low-use or idle times. In combination with the pay-as-you-use pricing model, automatic vertical scaling feature makes the cloud resource consumption really elastic and efficient.</p>
                      </blockquote>
            </div>
        </div>
    )
}

export default Pricing